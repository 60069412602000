.Contact {
  /* background: var(--colorSecondary); */
  background: inherit;
  padding-bottom: 10px;
}

.container {
  width: 90%;
  margin: auto;
}

.light_blue {
  /* background: var(--colorSecondary); */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
}

.img__container {
  display: flex;
  justify-content: flex-end;
  padding: 50px 0;
}

.img {
  width: 72vw;
}

.contact__lines {
  display: none;
}

.header {
  color: white;
  text-transform: uppercase;
  font-size: 7.5vw;
}

.footer__list {
  list-style: none;
  padding: 1em 0;
  font-size: 13px;
  color: var(--colorDefaultWhite);
}

.footer__list__title {
  color: var(--colorMain);
  font-weight: bold;
}

.social__list {
  color: var(--colorMain);
  list-style: none;
  display: flex;
  float: right;
  margin-top: 30px;
}

.social__list li:not(:last-child):after {
  content: "|";
  margin: 0 5px;
}

.form_side {
  /* margin: 50px auto; */
}

.sentence {
  color: var(--colorMain);
  font-size: 1.22rem;
  text-align: center;
  padding-bottom: 0.5em;
  margin-top: 40px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form input,
.form select,
.form textarea {
  width: 100%;
  margin: 1em 0;
  border-top: none;
  border-left: none;
  border-right: none;
  color: var(--colorMain);
  background: inherit;
  border-bottom: 0.5px solid var(--colorMain);
  font-family: var(--titleFont);
  font-size: 0.8rem;
}

/*.form select:invalid {*/
/*    color: white;*/
/*}*/

.form input::placeholder,
.form textarea::placeholder {
  /*color: var(--colorHover);*/
  opacity: 0.3;
  color: var(--colorMain);
}

.form input:focus,
.form select:focus,
.form textarea:focus {
  outline: none;
  border-bottom: 3px solid var(--colorSecondary);
}

.form .form__submit_btn {
  border: none;
  background-color: var(--colorMain);
  color: var(--colorDefaultWhite);
  height: 1.5lh;
  width: 64px;
  font-size: 1rem;
  align-self: last baseline;
}

.form .form__submit_btn:hover:enabled {
  opacity: 0.9;
}

.form .form__submit_btn:focus:enabled {
  border-bottom: none;
}

.form .form__submit_btn:active:enabled {
  opacity: 0.7;
}

.form .form__submit_btn:disabled,
.form .form__submit_btn[disabled] {
  /*border: 1px solid #999999;*/
  background-color: var(--colorSecondary);
  /*color: var(--colorMainlight);*/
}

.form_fields {
  /* columns: 2; */
}

.ShowAfter900W {
  display: none;
}

@media (min-width: 900px) {
  .Contact {
    /* padding-right: 200px; */
  }
  .light_blue {
    width: 62%;
  }

  .Contact {
    /* padding-top: 3em; */
    background: inherit;
  }

  .container {
    width: auto;
    display: flex;
    justify-content: space-between;
    /*width: 85%;*/
  }

  .heading_side {
    width: 62%;
    text-align: right;
    margin-top: 45px;
    margin-right: 10vw;
  }

  .img__container {
    display: flex;
    margin-top: 95px;
    justify-content: right;
    padding: 0;
  }

  .contact__lines {
    display: inherit;
    position: absolute;
    stroke: white;
    z-index: -1;
    /*right: 50vw;*/
    left: -23vw;
    height: 16vw;
    margin: 4.5vw 0;
  }

  .form_side {
    /* width: 29%; */
    /* margin-top: 50px; */
    /* padding-left: 2vw; */
  }

  .img {
    width: auto;
    height: 23vw;
  }

  .header {
    font-size: 1vw;
    color: var(--colorDefaultWhite);
    text-transform: uppercase;
  }

  .sentence {
    color: var(--colorMain);
    /* font-size: 2rem; */
    text-align: center;
    padding-bottom: 0.5em;
    margin-top: 0;
  }

  .contact_form {
    justify-content: flex-start;
    padding: 0;
  }

  .form {
    width: 100%;
    max-width: 500px;
  }

  .form input,
  .form select,
  .form textarea {
    color: var(--colorMain);
  }

  .form .form__submit_btn {
    align-self: flex-end;
  }
}
