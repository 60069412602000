.container {
    width: 85%;
    margin: 0 auto;
    padding: 4em 0;
    max-width: 1200px;
}

.gallery_side {
    display: flex;
    flex-direction: column-reverse;
}

.images {
    width: fit-content;
    margin: 40px auto 0;
}

.images img {
}

.image_top {
    height: 1rem;
    width: 100%;
    border: solid 2px var(--colorSecondary);
    border-bottom: none;
    border-radius: 1rem 1rem 0 0;
    background: white;
}

.image_gallery_container {
    border: solid 2px var(--colorSecondary);
    background: white;
}

.mobile_images .image_gallery_container {
    border-radius: 0 0 1rem 1rem;
    padding: 0.2rem;
}

.images_gallery_bullet {
    box-shadow: none;
    padding: 4px;
    position: absolute;
    bottom: -50px;
}

.images_gallery_bullet_active {
    background: var(--colorMain);
}

.image-gallery-bullets-container {

}

.info__heading h1 {
    color: white;
    background: var(--colorMain);
    font-size: 2rem;
    padding: 19px 42px;
}

.info__heading p {
    width: 100%;
    padding-top: 19px;
}


section h3 {
    color: var(--colorSecondary);
    font-size: 2rem;
}

section p {
    color: var(--colorMain);
    width: 100%;
    padding-bottom: 1rem;
}

.project_link {
    margin: 1em 0 3em 0;
}

@media (min-width: 1200px) {
    .container {
        display: grid;
        width: 70%;
        padding: 0;
        gap: 1rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: max-content
    }

    .info__heading {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .info__heading h1 {
        font-size: 2.5vw;
    }

    .big_gallery {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-column-end: 4;
    }


    .info__customer {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .info__need {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .info__solution {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .small_gallery {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 4;
        width: 50%;
        position: relative;
        bottom: -350px;
        right: -30%;
        z-index: 1;
    }

    .images {
        margin: 0 auto;
    }
}