.person {}

.person__odd {
    margin-top: 0px;
}

.heading {
    display: flex;
}

.head {
    display: flex;
}

.heading__img {
    width: 28%;
    margin-right: 5px;
}

.heading__text {
    color: var(--colorSecondary);
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.heading__name {
    font-size: 5.9vw;
    display: inline-block;
}

.heading__role{
    font-size: 2.6vw;
}

.heading__phone {
    font-size: .85rem;
}

.heading__phone:hover {
    opacity: 0.9;
}

.heading__bar {
    background-color: var(--colorMain);
    width: 10px;
    margin: 0 13px;
}

.description {
    color: var(--colorMain);
    line-height: 1.95;
    font-size: 16px;
}

.line {
    height: 0.12em;
    background-color: var(--colorSecondary);
    margin: 5px auto;
    width: 47vw;
}

.email {
    color: var(--colorMain);
    font-size: 3.5vw;
}


.hidden__wide_screen {
    display: block;
}

.hidden_mobile {
    display: none;
}

@media (min-width: 900px) {
    .person__odd {
        margin-top: 75px;
    }

    .heading {
        margin: 16px 0;
    }

    .head {
        display: block;
    }

    .heading__img {
        width: 100%;
        margin-right: 12px;
    }

    .heading__bar {
        margin: 3px;
    }

    .heading__name {
        font-size: 1.3rem;
    }

    .line {
    background-color: var(--colorMain);
    margin: 16px auto;
    width: 100%
    }

    .email {
        font-size: 16px;
    }

    .heading__role{
        font-size: 0.6rem;
    }

    .hidden__wide_screen {
        display: none;
    }

    .hidden_mobile {
        display: block;
    }
}