.dLogo {
  position: fixed;
  left: 1.5em;
  top: 0.5em;
  z-index: 20;
  color: var(--colorDefaultWhite);
  /* background-color: var(--colorSecondary); */
  /* padding: 0.2em 1em; */
  max-width: 30px;
  border-radius: 0.2em;
}

.dLogo:hover {
  opacity: 0.75;
}
.dLogoSize {
  width: 40px;
}
