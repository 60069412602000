.project__img__box {
    position: relative;
    z-index: 1;
    border: 0.5px solid #bdccd4;
    /*border-bottom-right-radius: 0.2em;*/
    /*border-bottom-left-radius: 0.2em;*/
}

.project__img {
    width: 100%;
    display: block;
    box-shadow: inset 0 0 0 1000px rgba(5, 68, 124, 0.8)
}

/*.project__info {*/
/*    text-align: center;*/
/*    padding: 1em;*/
/*}*/

.project__info__title {
    color: var(--colorSecondary);
    padding: 24px 0;
    width: 70%;
    font-size: 25px;
}

.project__info__content {
    color: var(--colorMain);
    font-size: 19px;
}

.project__hover {
    transition: opacity 0.5s;
    color: white;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project__img__box:hover > .project__hover{
    opacity: 1;
}

.image_top {
    height: 1em;
  border: 0.5px solid #bdccd4;
  border-bottom: none;
  border-top-left-radius: 4.2px;
  border-top-right-radius: 4.2px;
}

.circles_container {
    display: flex;
    grid-gap: 4px;
    margin: 5px;
}

.top_circle {
    padding: 3px;
    background: #bdccd4;
    border-radius: 50%;
}

.circle {
    background-color: var(--colorMain);
    border-radius: 50%;
    width: 26%;
    height: 36%;
    opacity: 0.8;
}

.circle:hover {
    background-color: var(--lightYellow);
}

.title {
    position: absolute;
    margin-left: auto;
    text-transform: uppercase;
    font-weight: bold;
    pointer-events: none;
}