@import url(https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
/* ******** Set to Zero the auto-css ******** */
*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

/* ******** Set Root varables of the webapp ******** */
:root {
  --titleFont: "Ubuntu Condensed", "Alef", sans-serif;
  --mainFont: "Oxygen", "Alef", sans-serif;
  --colorMain: #05447c;
  --colorMainlight: rgba(5, 68, 124, 0.8);
  --colorSecondary: #24aadf;
  --colorDefaultWhite: rgb(238, 238, 238);
  --transformMenu: 1000ms;
  --colorHover: #29abe2;
  --whiteHover: #ffffff;
  --mutedText: #bdccd4;
  --fixedFontSize: 1rem;

  /* Colors not from Dror banding*/
  --lightGray: #e8f2f9;
  --lightYellowGray: #e5e2d3;
  --lightYellow: #cdcdc3;
  --brownBoard: #a06344;
  --lightBrown: #dfb198;
  --lighterBrown: rgba(223, 177, 152, 0.3);
  --lightBlue: #a1c4c0;
  --lightGreen: #bccab1;
  --orange: #f57a03;
  --brightRed: #FF2211;
}


/* ******** Set Text font & size ******** */
body {
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*sans-serif;*/
  font-family: var(--mainFont);
}


.card-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

/*.card-1:hover {*/
/*  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);*/
/*}*/

.card-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.card-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.card-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.button {
  background-color: var(--colorMain);
  color: var(--colorDefaultWhite);
  padding: 1em 2em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: start;
  justify-self: end;
  border: none;
  font-size: 1rem;
  border-radius: 2em;
}

.button:hover {
  opacity: 0.9;
}

.button:focus:enabled {
  border-bottom: none;
}

.button:active:enabled {
  opacity: 0.7;
}


@media (min-width: 600px) {
  html {
    font-size: 18px;
  }

}

h1,h2,h3,h4,h5 {
  font-family: var(--titleFont);
}

a {
  text-decoration: none;
  color: inherit;
}
.container {
}


.fixed {
    position: fixed;
}
.Contact_Contact__3XJZl {
    background: var(--colorSecondary);
    padding-bottom: 10px;
}

.Contact_container__3DmEF {
    width: 67%;
    margin: 1em auto;
}

.Contact_light_blue__Xb-Jp {
    background: var(--colorSecondary);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -10;
}

.Contact_img__container__KoT-m {
    display: flex;
    justify-content: flex-end;
    padding: 50px 0;
}

.Contact_img__EiayA {
    width: 72vw;
}

.Contact_contact__lines__2LKVW {
    display: none;
}

.Contact_header__Punsq {
    color: white;
    text-transform: uppercase;
    font-size: 7.5vw;;
}

.Contact_footer__list__1e_zf {
    list-style: none;
    padding: 1em 0;
    font-size: 13px;
    color: var(--colorDefaultWhite);
}

.Contact_footer__list__title__39eei {
    color: var(--colorMain);
    font-weight: bold;
}

.Contact_social__list__3uvh8 {
    color: var(--colorMain);
    list-style: none;
    display: flex;
    float: right;
    margin-top: 30px;
}

.Contact_social__list__3uvh8 li:not(:last-child):after {
    content: "|";
    margin: 0 5px;
}

.Contact_form_side__LhSQl {
    margin: 50px auto;
}

.Contact_sentence__2u7Ld {
    color: var(--colorMain);
    font-size: 1.22rem;
    text-align: center;
    padding-bottom: 0.5em;
    margin-top: 100px;
}

.Contact_form__pF3Iu {
    display: flex;
    flex-direction: column;
}

.Contact_form__pF3Iu input,
.Contact_form__pF3Iu select,
.Contact_form__pF3Iu textarea {
    width: 100%;
    margin: 1em 0;
    border-top: none;
    border-left: none;
    border-right: none;
    color: white;
    background: inherit;
    border-bottom: 1px solid var(--colorMain);
    font-family: var(--mainFont);
    font-size: 0.8rem;
}

.Contact_form__pF3Iu select:invalid {
    color: white;
    opacity: 0.6;
}

.Contact_form__pF3Iu select:focus {
    color: var(--colorMain);
}

.Contact_form__pF3Iu input::placeholder,
.Contact_form__pF3Iu textarea::placeholder {
    color: white;
    opacity: 0.6;
}

.Contact_form__pF3Iu input:focus,
.Contact_form__pF3Iu select:focus,
.Contact_form__pF3Iu textarea:focus {
    outline: none;
    border-bottom: 3px solid var(--colorSecondary);
}


.Contact_form__pF3Iu .Contact_form__submit_btn__clv2n {
    border: none;
    background-color: var(--colorMain);
    color: var(--colorDefaultWhite);
    height: 1.5lh;
    width: 64px;
    font-size: 1rem;
    align-self: center;
}

.Contact_form__pF3Iu .Contact_form__submit_btn__clv2n:hover:enabled {
    opacity: 0.9;
}

.Contact_form__pF3Iu .Contact_form__submit_btn__clv2n:focus:enabled {
    border-bottom: none;
}

.Contact_form__pF3Iu .Contact_form__submit_btn__clv2n:active:enabled {
    opacity: 0.7;
}

.Contact_form__pF3Iu .Contact_form__submit_btn__clv2n:disabled,
.Contact_form__pF3Iu .Contact_form__submit_btn__clv2n[disabled]{
    /*border: 1px solid #999999;*/
    background-color: var(--colorSecondary);
    /*color: var(--colorMainlight);*/
}

.Contact_form_fields__1PMgy {
    columns: 2
}


.Contact_ShowAfter900W__14O4A {
    display: none;
}

@media (min-width: 900px) {
    .Contact_Contact__3XJZl {
        padding-right: 200px;
    }
    .Contact_light_blue__Xb-Jp {
        width: 62%;
    }

    .Contact_Contact__3XJZl {
        padding-top: 3em;
        background: inherit;
    }

    .Contact_container__3DmEF {
        width: auto;
        display: flex;
        justify-content: space-between;
        /*width: 85%;*/
    }

    .Contact_heading_side__3x2x0 {
        width: 62%;
        text-align: right;
        margin-top: 45px;
        margin-right: 10vw;
    }

    .Contact_img__container__KoT-m {
        display: flex;
        margin-top: 95px;
        justify-content: right;
        padding: 0;
    }

    .Contact_contact__lines__2LKVW {
        display: inherit;
        position: absolute;
        stroke: white;
        z-index: -1;
        /*right: 50vw;*/
        left: -23vw;
        height: 16vw;
        margin: 4.5vw 0;
    }

    .Contact_form_side__LhSQl {
        width: 29%;
        margin-top: 50px;
        padding-left: 2vw;
    }

    .Contact_img__EiayA {
        width: auto;
        height: 23vw;
    }

    .Contact_header__Punsq {
        font-size: 1.75vw;
        color: var(--colorDefaultWhite);
        text-transform: uppercase;
    }

    .Contact_sentence__2u7Ld {
        color: var(--colorMain);
        font-size: 2rem;
        text-align: center;
        padding-bottom: 0.5em;
        margin-top: 0;
    }

    .Contact_contact_form__3Bxz8 {
        justify-content: flex-start;
        padding: 0;
    }

    .Contact_form__pF3Iu {
        width: 100%;
        max-width: 500px;
    }

    .Contact_form__pF3Iu input,
    .Contact_form__pF3Iu select,
    .Contact_form__pF3Iu textarea {
        color: var(--colorMain)
    }

    .Contact_form__pF3Iu input::placeholder,
    .Contact_form__pF3Iu textarea::placeholder {
        color: var(--colorSecondary);
    }

    .Contact_form__pF3Iu select:invalid {
        color: var(--colorSecondary);
    }

    .Contact_form__pF3Iu select:focus {
        color: var(--colorMain);
    }

    .Contact_form__pF3Iu .Contact_form__submit_btn__clv2n {
        align-self: flex-end;
    }
}
.hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-background {
  background-image: url(/static/media/hero-img-02.70e953b6.jpeg);
  background-position: center;
  background-color: var(--lightGray);
  background-size: cover;
}

.hero__white_lines {
  position: absolute;
  top: 20vh;
  stroke: white;
  width: 100%;
}

#hero-slogan {
  font-size: 3rem;
  text-align: center;
  color: white;
  word-spacing: 9999rem;
  text-transform: uppercase;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}

@media (min-width: 900px) {
  #hero-slogan {
    font-size: 4rem;

  }
  .hero__white_lines {
    height: 13%;
    top: 26vh;
    position: absolute;
    margin: auto;
  }
}
.WhoWeAre_container__1qUFP {
  position: relative;
  width: 84%;
  margin: 124px auto 0;
  max-width: 1200px;
}


.WhoWeAre_img__2jbUN {
  width: 81%;
  height: 46vw;
  object-fit: cover;
  max-width: 100%;
  display: block;
}

.WhoWeAre_text_cube__2fLzb {
  background-color: var(--colorMain);
  color: white;
  opacity: 0.8;
  text-transform: uppercase;
}

.WhoWeAre_text_cube__2fLzb {
  position: absolute;
  font-size: 4.2vw;
  padding: 5% 10%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 38%;
  right: 0;
  top: 4vw
}

.WhoWeAre_textbox_1__2Gwco {
  margin-top: 34px;
  margin-bottom: 0.6lh;
}

.WhoWeAre_textbox_2__3WGA2 {
  margin-top: 0.6lh;
}

.WhoWeAre_drorsoft_text__3ilql {
  color: var(--colorMain);
  font-size: 16px;
}

@media (min-width: 900px) {

  .WhoWeAre_container__1qUFP {
    margin: auto;
    width: 75%;
    padding-top: 198px;
    display: grid;
    grid-template-columns: [start] 9% [blue-cube-start] 10lh [blue-cube-end] 1fr [img-end] 40% [end];
    grid-template-rows: [start] auto [blue-cube-start] 3lh [img-end] auto [end];
    align-items: center;
  }

  .WhoWeAre_img_side__3g_7R {
    grid-column-start: start;
    grid-column-end: img-end;
    grid-row-start: start;
    grid-row-end: img-end;
  }

  .WhoWeAre_img__2jbUN {
    width: 100%;
    height: auto;
  }

  .WhoWeAre_text_cube__2fLzb {
    position: static;
    font-size: 1.4em;
    padding: 0.5em 50% 3em 10%;
    height: 100%;
    width: 100%;
  }

  .WhoWeAre_text_cube__2fLzb {
    grid-column-start: blue-cube-start;
    grid-column-end: blue-cube-end;
    grid-row-start: blue-cube-start;
    grid-row-end: end;
  }

  .WhoWeAre_textbox_1__2Gwco {
    padding: 0 57px;
    grid-column-start: img-end;
    grid-column-end: end;
    grid-row-start: start;
    grid-row-end: img-end;
  }

  .WhoWeAre_textbox_2__3WGA2 {
    margin: 50px 50px 0;
    width: 45%;
  }

  .WhoWeAre_textbox_2__3WGA2 {
    grid-column-start: blue-cube-end;
    grid-column-end: end;
    grid-row-start: img-end;
    grid-row-end: end;
  }

  .WhoWeAre_hide__PapTT {
    display: none;
  }
}
.RecentProjectCube_project__img__box__1rNzI {
    position: relative;
    z-index: 1;
    border: 0.5px solid #bdccd4;
    /*border-bottom-right-radius: 0.2em;*/
    /*border-bottom-left-radius: 0.2em;*/
}

.RecentProjectCube_project__img__3sBuz {
    width: 100%;
    display: block;
    box-shadow: inset 0 0 0 1000px rgba(5, 68, 124, 0.8)
}

/*.project__info {*/
/*    text-align: center;*/
/*    padding: 1em;*/
/*}*/

.RecentProjectCube_project__info__title__3q7Ty {
    color: var(--colorSecondary);
    padding: 24px 0;
    width: 70%;
    font-size: 25px;
}

.RecentProjectCube_project__info__content__3uX7m {
    color: var(--colorMain);
    font-size: 19px;
}

.RecentProjectCube_project__hover__3Evtz {
    transition: opacity 0.5s;
    color: white;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.RecentProjectCube_project__img__box__1rNzI:hover > .RecentProjectCube_project__hover__3Evtz{
    opacity: 1;
}

.RecentProjectCube_image_top__3SxHe {
    height: 1em;
  border: 0.5px solid #bdccd4;
  border-bottom: none;
  border-top-left-radius: 4.2px;
  border-top-right-radius: 4.2px;
}

.RecentProjectCube_circles_container__1NZzN {
    display: flex;
    grid-gap: 4px;
    margin: 5px;
}

.RecentProjectCube_top_circle__2uY2S {
    padding: 3px;
    background: #bdccd4;
    border-radius: 50%;
}

.RecentProjectCube_circle__3F6fH {
    background-color: var(--colorMain);
    border-radius: 50%;
    width: 26%;
    height: 36%;
    opacity: 0.8;
}

.RecentProjectCube_circle__3F6fH:hover {
    background-color: var(--lightYellow);
}

.RecentProjectCube_title__2XCiB {
    position: absolute;
    margin-left: auto;
    text-transform: uppercase;
    font-weight: bold;
    pointer-events: none;
}
.RecentProjects_container__2pgaL {
  width: 70%;
  margin: 1em auto;
}

.RecentProjects_img__31f17 {
  width: 100%;
}

.RecentProjects_header__BWfmo {
  color: var(--colorDefaultWhite);
  background-color: var(--colorMain);
  opacity: 0.8;
  width: 42vw;
  padding: 1em;
  text-transform: uppercase;

}

.RecentProjects_container__grid__2BpHQ {
  padding: 2em 0;
}

.RecentProjects_projects_flex__3c4gI {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*.project_cube {*/
/*  width: 70%;*/
/*  margin: 0 auto;*/
/*}*/

.RecentProjects_project_cube__xDy8r + .RecentProjects_project_cube__xDy8r {
  margin-top: 3em;
}

.RecentProjects_lines_container__1mfQ5 {
  padding: 1em 0;
  display: flex;
  justify-content: flex-end;
}

.RecentProjects_blue_lines__9C1De {
  stroke: var(--colorMain);
  transform: scaleX(-1);
  width: 100%;
}

.RecentProjects_header_link__1S2ZM {
  display: none;
}

.RecentProjects_bottom_link__2RrKj {
  color: var(--colorMain);
  text-transform: uppercase;
  font-size: 18px;
  display: flex;
  justify-content: end;
}

@media (min-width: 900px) {
  .RecentProjects_container__2pgaL {
    margin: 0 auto;
    padding: 2em 0;
    width: 71%;
  }

  .RecentProjects_container__grid__2BpHQ {
    display: flex;
    grid-gap: 50px;
    flex-wrap: wrap;
    justify-content: left;
  }

  .RecentProjects_img__31f17 {
    width: 48%;
    display: block;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .RecentProjects_header__BWfmo {
    width: -webkit-min-content;
    width: min-content;
    color: var(--colorDefaultWhite);
    background-color: var(--colorMain);
    font-size: 2vw;
    padding: 1.2em 1.3em;
    margin-bottom: 42px;
  }

  .RecentProjects_header_link__1S2ZM {
    display: inherit;
    text-align: center;
    padding-top: 17px;
    font-size: 15px;
  }

  .RecentProjects_projects_flex__3c4gI {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .RecentProjects_project_cube__xDy8r {
    width: 385px;
    margin-top: 0;
  }

  .RecentProjects_project_cube__xDy8r + .RecentProjects_project_cube__xDy8r {
    margin-top: 0;
  }

  .RecentProjects_bottom_link__2RrKj {
  color: var(--colorMain);
  text-transform: uppercase;
  font-size: 18px;
  text-align: right;
  }
}
.NumberedText_NumberedText__1uTCV {
    display: flex;
    /*justify-content: space-between;*/
    padding: 1em;
    break-inside: avoid-column;
}

.NumberedText_number__1fQtD {
    color: var(--colorSecondary);
    justify-self: right;
    align-self: start;
    margin-top: -0.1em;
    font-size: 3rem;
    width: 2ch;
}

.NumberedText_line__1lRYu {
    background: var(--colorMain);
    justify-self: center;
    padding: 3px;
    margin: 0 16px;
}

.NumberedText_text_wrapper__2WFMS {
    text-align: left;
    width: 77%;
    color: var(--colorMain);
}

.NumberedText_text_title__3AiqK {
    color: var(--colorSecondary);
    text-transform: uppercase;
}

.NumberedText_content__2-bfK {
    font-size: 1rem;
}

.NumberedText_project_img__2tFYO {
    width: 1.9ch;
}


.WhatWeOffer_header_container__xm2t_ {
    position: relative;
    width: 84%;
    margin: 124px auto 0;
}

.WhatWeOffer_img__2IIX6 {
    width: 81%;
    height: 45vw;
    max-width: 100%;
    display: block;
    object-fit: cover;
}

.WhatWeOffer_text_cube__1g0p- {
  background-color: var(--colorMain);
  color: white;
  opacity: 0.8;
  text-transform: uppercase;
}

.WhatWeOffer_text_cube__1g0p- {
  position: absolute;
  font-size: 4.2vw;
  padding: 5% 6%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 38%;
  right: 0;
  top: 4vw
}

/*.header {*/
/*    background-color:  var(--colorMain);*/
/*    opacity: 0.8;*/
/*    color: white;*/
/*    padding: 2em;*/
/*    text-transform: uppercase;*/
/*    margin-top: -1.5em;*/
/*}*/

@media (min-width: 900px) {
    .WhatWeOffer_WhatWeOffer__67z6u {
        padding: 2em 0;
        margin-top: -2em;
    }
    .WhatWeOffer_header_container__xm2t_ {
        display: flex;
        margin: 2em auto;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .WhatWeOffer_blue_cube__15Y6t {
        background: var(--colorMain);
        position: relative;
        left: 15vw;
        bottom: -2em;
        height: 7vw;
        width: 6.5vw;
    }

    .WhatWeOffer_text_cube__1g0p- {
        position: relative;
        font-size: 1.8vw;
        padding: 2% 5% 2% 2%;
        height: 23vw;
        width: 17%;
        right: auto;
        top: auto;
        left: -13vw;
    }

    .WhatWeOffer_img__2IIX6 {
        height: 40vw;
        width: 80vw;
        object-fit: cover;
    }

    .WhatWeOffer_container__2rwEX {
        display: flex;
    }

    .WhatWeOffer_offers__container__373hI {
        columns: 2
    }

    .WhatWeOffer_offer__container__1tmvT {

    }

    .WhatWeOffer_left_cube__2fi1c {
        width: 81px;
        height: 86.9px;
        background: var(--colorMain);
        margin-right: 203px;
        margin-top: 170px;
    }

    .WhatWeOffer_right_cube__3Ng9V {
        width: 393px;
        height: 222px;
        background: var(--colorMain);
        margin-left: 99px;
        margin-top: 250px;
    }

    .WhatWeOffer_numbered_offer__3GuVm {

    }

    /*.header {*/
    /*    width: 48%;*/
    /*    opacity: 1;*/
    /*    font-size: 1.5rem;*/
    /*    padding: 0.2em 0.3em;*/
    /*    text-transform: uppercase;*/
    /*    margin-top: 0;*/
    /*}*/

    /*.numbered_offer {*/
    /*    max-width: 700px;*/
    /*}*/

    /*.container:nth-of-type(3) .numbered_offer,*/
    /*.container:nth-of-type(5) .numbered_offer,*/
    /*.container:nth-of-type(7) .numbered_offer*/
    /*{*/
    /*    margin-left: 5em;*/
    /*}*/
}

/*@media (min-width: 1200px) {*/
/*    .container:nth-of-type(3) .numbered_offer,*/
/*    .container:nth-of-type(5) .numbered_offer,*/
/*    .container:nth-of-type(7) .numbered_offer*/
/*    {*/
/*        margin-left: 13em;*/
/*    }*/
/*}*/

/*@media (min-width: 1800px) {*/
/*    .container:nth-of-type(3) .numbered_offer,*/
/*    .container:nth-of-type(5) .numbered_offer,*/
/*    .container:nth-of-type(7) .numbered_offer*/
/*    {*/
/*        margin-left: 18em;*/
/*    }*/
/*}*/
/* ********** THE BURGER ********** */
/* Basic Burger Menu*/
#menuBurger {
    position: fixed;
    right: 1.5em;
    top: 1.5em;
    z-index: 30;
}

#menuBurger:hover {
    cursor: pointer;
}

/* Every peace of the Burger */
#menuBurger .menuBurgerLevel {
    position: relative;
    top: 0;
    width: 30px;
    height: 3px;
    margin-bottom: 4px;
    transition: all var(--transformMenu) ease-in-out;
    transition-property: transform, top;
}


/* Setting the positions by sets */
#menuBurger #m1 {
    --burger: 0deg;
    --plus: 270deg;
    --x: 135deg;
    --xx: 405deg;
    --top: 7px;
}

#menuBurger #m2 {
    --burger: 0deg;
    --plus: -540deg;
    --x: 225deg;
    --xx: 495deg;
    --top: 0px;
}

#menuBurger #m3 {
    --burger: 0deg;
    --plus: -360deg;
    --x: 225deg;
    --xx: 495deg;
    --top: -7px;
}

/*  ********** Mouse effects the Burger ********** */
#menuBurger:hover .menuBurgerLevel {
    transform: rotate(var(--plus));
    top: var(--top);
}

#menuBurger.active .menuBurgerLevel {
    transform: rotate(var(--x));
    top: var(--top);
}

#menuBurger.active:hover .menuBurgerLevel {
    transform: rotate(var(--xx));
    top: var(--top);
}

/* ********** THE MENU BOX ********** */

/* Design the Box */
#menuBox {
    position: fixed;
    z-index: 25;
    right: -100vw;
    width: 100vw;
    transition: right var(--transformMenu) ease-in-out;
}

/* Design the Box BG */
#menuBox::before {
    content: "";
    position: fixed;
    top: 0;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    background-color: var(--colorMain);
    opacity: 0.9;
    transition: right var(--transformMenu) ease-in-out;
    z-index: -1;
}

#menuBox.active,
#menuBox.active::before {
    right: 0;
}

.menuItemsContainer {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    color: var(--mutedText);
}

.menuAnchor {
    padding: .26em 0;
}



#menuBox #menuAnchors .menuAnchor:hover,
#menuBox .menuSocial :hover,
.menuBurgerLevel:hover,
.comInfo__list a:hover {
    color: var(--whiteHover);
}

/* Design the Social Box */
#menuBox .menuSocial {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#menuBox .menuSocial a{
    text-transform: uppercase;
}

#menuBox .menuSocial .socialBtn svg {
    height: 35px;
    margin-top: 11px;
    margin-left: 20px;
}

#menuBox .menuSocial .socialBtn svg:hover {
    cursor: pointer;
}

#menuBox .menuSocial .socialBtn svg path {
    fill: var(--colorDefaultWhite);
}

#menuBox .menuSocial .socialBtn svg path:hover {
    fill: var(--colorHover);
}


#m1.logoOnWhite,
#m2.logoOnWhite,
#m3.logoOnWhite {
    background-color: var(--colorMain);
}

.comInfo {
    display: none;
}

.comInfo__list {
    list-style: none;
}

.comInfo__list li {
    padding: 0.1em 0;
    font-size: 0.9rem;
}

@media (min-width: 900px) {
    .menuAnchor {
        padding: .2em 0;
    }

    #menuBox::before {
        right: -23em;
        width: 23em;
    }

    .menuItemsContainer {
        text-align: right;
        align-items: flex-end;
        padding: 1.5em;
    }

    .comInfo {
        display: block;
    }

    .menuSocial {
        justify-content: space-between;
        font-size: .9rem;
    }

}
.Fixed_bar__1qAR3{
    /*background-color: #0979b2;*/
    /*height: 4em;*/
    /*width: 100%;*/
    /*!*position: fixed;*!*/
    /*will-change: transform;*/
    z-index: 30;
}
.CTA_cta__3p40t {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.5rem 3rem;
}

.CTA_links__1Hl_m {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: auto;
}

.CTA_background__3H0d5, .CTA_transparent__J54eG {
    background: var(--colorMain);
    color: white;
    /*width: 100%;*/
    z-index: 15;
}

@media (min-width: 900px) {
    .CTA_cta__3p40t {
        position: fixed;
        bottom: 50vh;
        left: 2.5rem;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background: white;
        /*width: 160px;*/
        transform: translate(-50%, -100%) rotate(90deg);
        letter-spacing: 1px;
        font-size: 0.8rem;
        transition: color 1s;
        z-index: 15;
        padding: 0.5rem;
    }

    .CTA_links__1Hl_m span {
        margin: 0 0.5rem;
    }

    .CTA_background__3H0d5 {
        color: var(--colorMain);
        background: white;
    }

    .CTA_transparent__J54eG {
        color: white;
        background: none;
    }
}
.Person_person__2nTJg {}

.Person_person__odd__3Uxt- {
    margin-top: 0px;
}

.Person_heading__Czgsy {
    display: flex;
}

.Person_head__klmWa {
    display: flex;
}

.Person_heading__img__2-dUt {
    width: 28%;
    margin-right: 5px;
}

.Person_heading__text__3OXUL {
    color: var(--colorSecondary);
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.Person_heading__name__2j_Eg {
    font-size: 5.9vw;
    display: inline-block;
}

.Person_heading__role__KJ0Nc{
    font-size: 2.6vw;
}

.Person_heading__phone__MwPnv {
    font-size: .85rem;
}

.Person_heading__phone__MwPnv:hover {
    opacity: 0.9;
}

.Person_heading__bar__1sZ91 {
    background-color: var(--colorMain);
    width: 10px;
    margin: 0 13px;
}

.Person_description__1Iew- {
    color: var(--colorMain);
    line-height: 1.95;
    font-size: 16px;
}

.Person_line__27K4O {
    height: 0.12em;
    background-color: var(--colorSecondary);
    margin: 5px auto;
    width: 47vw;
}

.Person_email__2L01Z {
    color: var(--colorMain);
    font-size: 3.5vw;
}


.Person_hidden__wide_screen__3gIrR {
    display: block;
}

.Person_hidden_mobile__1bmsR {
    display: none;
}

@media (min-width: 900px) {
    .Person_person__odd__3Uxt- {
        margin-top: 75px;
    }

    .Person_heading__Czgsy {
        margin: 16px 0;
    }

    .Person_head__klmWa {
        display: block;
    }

    .Person_heading__img__2-dUt {
        width: 100%;
        margin-right: 12px;
    }

    .Person_heading__bar__1sZ91 {
        margin: 3px;
    }

    .Person_heading__name__2j_Eg {
        font-size: 1.3rem;
    }

    .Person_line__27K4O {
    background-color: var(--colorMain);
    margin: 16px auto;
    width: 100%
    }

    .Person_email__2L01Z {
        font-size: 16px;
    }

    .Person_heading__role__KJ0Nc{
        font-size: 0.6rem;
    }

    .Person_hidden__wide_screen__3gIrR {
        display: none;
    }

    .Person_hidden_mobile__1bmsR {
        display: block;
    }
}
.OurTeam_container__3c_ve {
    width: 84%;
    margin: 60px auto;
}

.OurTeam_header__3Akiv {
    background-color:  var(--colorMain);
    opacity: 0.8;
    color: white;
    padding: 2em;
    text-transform: uppercase;
}

.OurTeam_blue_cubes_row__2_m1T {
    display: flex;
    justify-content: space-around;
    padding: 0 1em;
}

.OurTeam_blue_cube__30xPW {
    width: 2em;
    height: 2em;
    background-color: var(--colorMain);
}

.OurTeam_person__D1ufb, .OurTeam_teamCard__2hfo- {
    margin-top: 2em;
}

.OurTeam_lines__2gj6- {
    display: none;
}

@media (min-width: 900px) {
    .OurTeam_container__3c_ve {
        display: flex;
        justify-content: center;
        margin:8em auto 0;
        width: 67%;
        align-items: center;
        grid-gap: 55px;
        gap: 55px;
    }

    .OurTeam_container__blue_cubes__2Af0g {
        grid-template-columns: 1fr;
    }

    .OurTeam_header__3Akiv {
        opacity: 1;
        grid-column: 1 / 3;
        font-size: 2rem;
        padding: 0.2em 0.3em;
        margin: .5em 0;
        justify-self: stretch;
    }

    .OurTeam_person__D1ufb {
        margin-top: 0;
        align-self: stretch;
        width: 187px;
        position: relative;
        font-size: 14px;
    }

    .OurTeam_lines__2gj6- {
        display: flex;
        stroke: var(--colorSecondary);
        width: 433%;
        position: absolute;
        transform-origin: 0 0;
        left: 1vw;
        top: 9vw;
        z-index: -1;
    }

    .OurTeam_teamCard__2hfo- {
        grid-column: 1/3;
        justify-self: center;
    }
}
.Project_container__Q2DVo {
    width: 85%;
    margin: 0 auto;
    padding: 4em 0;
    max-width: 1200px;
}

.Project_gallery_side__qYOoF {
    display: flex;
    flex-direction: column-reverse;
}

.Project_images__11gT8 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 40px auto 0;
}

.Project_images__11gT8 img {
}

.Project_image_top__4yHMu {
    height: 1rem;
    width: 100%;
    border: solid 2px var(--colorSecondary);
    border-bottom: none;
    border-radius: 1rem 1rem 0 0;
    background: white;
}

.Project_image_gallery_container__2eGG4 {
    border: solid 2px var(--colorSecondary);
    background: white;
}

.Project_mobile_images__VfAfA .Project_image_gallery_container__2eGG4 {
    border-radius: 0 0 1rem 1rem;
    padding: 0.2rem;
}

.Project_images_gallery_bullet__33ivw {
    box-shadow: none;
    padding: 4px;
    position: absolute;
    bottom: -50px;
}

.Project_images_gallery_bullet_active__NJyZK {
    background: var(--colorMain);
}

.Project_image-gallery-bullets-container__i4uSt {

}

.Project_info__heading__2s3FF h1 {
    color: white;
    background: var(--colorMain);
    font-size: 2rem;
    padding: 19px 42px;
}

.Project_info__heading__2s3FF p {
    width: 100%;
    padding-top: 19px;
}


section h3 {
    color: var(--colorSecondary);
    font-size: 2rem;
}

section p {
    color: var(--colorMain);
    width: 100%;
    padding-bottom: 1rem;
}

.Project_project_link__3RZex {
    margin: 1em 0 3em 0;
}

@media (min-width: 1200px) {
    .Project_container__Q2DVo {
        display: grid;
        width: 70%;
        padding: 0;
        grid-gap: 1rem;
        gap: 1rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: -webkit-max-content;
        grid-auto-rows: max-content
    }

    .Project_info__heading__2s3FF {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .Project_info__heading__2s3FF h1 {
        font-size: 2.5vw;
    }

    .Project_big_gallery__ysybe {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-column-end: 4;
    }


    .Project_info__customer__1VTRM {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .Project_info__need__QAYre {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .Project_info__solution__3YcAD {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .Project_small_gallery__1JZpJ {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 4;
        width: 50%;
        position: relative;
        bottom: -350px;
        right: -30%;
        z-index: 1;
    }

    .Project_images__11gT8 {
        margin: 0 auto;
    }
}
.wrapper_wrapper__1HWsV {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
}

@media (min-width: 1200px) {
    .wrapper_wrapper__1HWsV {
        grid-template-rows: 4rem auto;
    }
}
.Landing_container__1HwRn {
  /* position: relative; */
  /* width: 84%; */
  margin: 60px 30px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.Landing_flexit__3E1XU {
  display: flex;
  flex-direction: column;
}

.Landing_flexitcol__3BvBz {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  padding-top: 50px;
}

.Landing_text_cube__3cBMh {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 10%;
  background-color: var(--colorMain);
  font-size: 2.5rem;
  /* width: 25%;
  height: 10vh;
  min-width: 300px; */
  color: white;
  opacity: 0.8;
  text-transform: uppercase;
}

.Landing_contact_cube__1cWrz {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding: 15px;
  color: var(--colorMain);
  border: 1px solid #2dabe3;
  font-family: var(--titleFont);
  font-size: 19.9px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  line-height: 1.22;
  width: 80vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 300px;
  margin-top: 20px;
}

.Landing_images_gallery_bullet__2-lP4 {
  box-shadow: none;
  padding: 4px;
  position: absolute;
  bottom: -50px;
  background-color: red;
  width: 90px;
}

.Landing_images_gallery_bullet_active__O2KJ9 {
  background: var(--colorMain);
}
.Landing_quoteSvg__uy4aw {
  width: 50px;
  height: 40px;
  border-bottom: 2px solid #2dabe3;
  position: relative;
  top: 20px;
  z-index: 30;
}
.Landing_swiperCont__Z9BV4 {
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
  height: 200px;
  .swiper-pagination {
    width: 35% !important;
  }
}

.Landing_swiper__18UDH {
  width: 100%;
  height: 100%;
}

.Landing_swiperSlide__10YJZ {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: MyriadHebrew;
  font-size: 18.8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #2dabe3;
}

.Landing_swiperSlide__10YJZ img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Landing_quoteName__YDSGd {
  font-size: 15px;
  position: relative;
  top: 80px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  color: var(--colorMain);
}

.Landing_contDesc__3pqYv {
  font-family: var(--titleFont);
  font-size: 20px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  max-width: 90vw;
  color: #05447c;
}
.Landing_imgGallery__1SxsY {
  width: 90vw;
}
.Landing_imgGalleryDesktop__2kivM {
  .image-gallery-bullets {
    bottom: 20px;
  }
  .image-gallery-bullet.active {
    background-color: var(--colorMain);
  }
  /* max-height: 30vh; */
}
.Landing_imgGalleryMobile__1dZBx {
  max-width: 170px;
  margin-top: 20px;
  .image-gallery-bullet.active {
    background-color: var(--colorMain);
  }
  .image-gallery-bullets {
    top: 20px;
  }
}
.Landing_bottomLines__1qTFn {
  display: none;
}

@media (min-width: 900px) {
  .Landing_container__1HwRn {
    position: relative;
    width: 84%;
    margin: 6vh auto 0 auto;
    max-width: 1200px;
    height: 94vh;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
  }
  .Landing_flexit__3E1XU {
    display: flex;
    flex-direction: row;
  }

  .Landing_flexitcol__3BvBz {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }

  .Landing_text_cube__3cBMh {
    order: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10%;
    background-color: var(--colorMain);
    font-size: 2rem;
    width: 25%;
    height: 10vh;
    min-width: 300px;
    color: white;
    opacity: 0.8;
    text-transform: uppercase;
    margin: 0;
  }

  .Landing_contact_cube__1cWrz {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding: 15px;
    order: 2;
    color: var(--colorMain);
    border: 1px solid #000000;
    font-family: var(--titleFont);
    font-size: 19.9px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 1.22;
    width: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 300px;
    margin-top: 0;
  }

  .Landing_images_gallery_bullet__2-lP4 {
    box-shadow: none;
    padding: 4px;
    position: absolute;
    bottom: -50px;
    background-color: red;
    width: 90px;
  }

  .Landing_images_gallery_bullet_active__O2KJ9 {
    background: var(--colorMain);
  }

  .Landing_swiperCont__Z9BV4 {
    margin-top: 0;
    margin-bottom: 0;
    order: 5;
    width: 40vw;
    /* max-width: 670px; */
    height: 180px;
    .swiper-pagination {
      width: 35% !important;
    }
  }

  .Landing_swiper__18UDH {
    width: 100%;
    height: 100%;
  }

  .Landing_swiperSlide__10YJZ {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: MyriadHebrew;
    font-size: 18.8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #2dabe3;
  }

  .Landing_swiperSlide__10YJZ img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .Landing_contDesc__3pqYv {
    order: 4;
    font-family: var(--titleFont);
    font-size: 22.4px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    width: 40vw;
    max-width: 670px;
    color: #05447c;
    align-self: baseline;
  }
  .Landing_imgGalleryDesktop__2kivM {
    width: 30vw;
    order: 3;
    max-width: 760px;
    .image-gallery-bullets {
      bottom: -20px;
    }
    .image-gallery-bullet.active {
      background-color: var(--colorMain);
    }
    /* max-height: 30vh; */
  }
  .Landing_imgGalleryMobile__1dZBx {
    width: 10vw;
    order: 6;
    position: relative;
    margin-top: 0;
    /* top: -20%; */
    /* margin: auto; */
    right: 5%;
    top: 25%;
    max-width: 170px;
    .image-gallery-bullet.active {
      background-color: var(--colorMain);
    }
    .image-gallery-bullets {
      top: -20px;
    }
  }
  .Landing_bottomLines__1qTFn {
    order: 8;
    position: absolute;
    right: -5%;
    /* margin: auto; */
    bottom: 0px;
    display: flex;
    align-items: flex-end;
    z-index: 90;
  }
  .Landing_bottomLinesSvg__1vzBm {
    height: 300px;
  }
}

@media (min-width: 1700px) {
  .Landing_imgGalleryDesktop__2kivM {
    width: 50vw;
  }
  .Landing_text_cube__3cBMh {
    padding: 15%;
    height: 20vh;
  }
  .Landing_contact_cube__1cWrz {
    min-width: 350px;
  }
}

.dlogo_dLogo__2Neag {
  position: fixed;
  left: 1.5em;
  top: 0.5em;
  z-index: 20;
  color: var(--colorDefaultWhite);
  /* background-color: var(--colorSecondary); */
  /* padding: 0.2em 1em; */
  max-width: 30px;
  border-radius: 0.2em;
}

.dlogo_dLogo__2Neag:hover {
  opacity: 0.75;
}
.dlogo_dLogoSize__2SIqJ {
  width: 40px;
}

.LandingContactBox_Contact__1Jvzm {
  /* background: var(--colorSecondary); */
  background: inherit;
  padding-bottom: 10px;
}

.LandingContactBox_container__2bvu_ {
  width: 90%;
  margin: auto;
}

.LandingContactBox_light_blue__jt4gA {
  /* background: var(--colorSecondary); */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
}

.LandingContactBox_img__container__EYzVI {
  display: flex;
  justify-content: flex-end;
  padding: 50px 0;
}

.LandingContactBox_img__nQOtJ {
  width: 72vw;
}

.LandingContactBox_contact__lines__2sMWC {
  display: none;
}

.LandingContactBox_header__2uVZU {
  color: white;
  text-transform: uppercase;
  font-size: 7.5vw;
}

.LandingContactBox_footer__list__mMfnG {
  list-style: none;
  padding: 1em 0;
  font-size: 13px;
  color: var(--colorDefaultWhite);
}

.LandingContactBox_footer__list__title__26HDT {
  color: var(--colorMain);
  font-weight: bold;
}

.LandingContactBox_social__list__2EKyJ {
  color: var(--colorMain);
  list-style: none;
  display: flex;
  float: right;
  margin-top: 30px;
}

.LandingContactBox_social__list__2EKyJ li:not(:last-child):after {
  content: "|";
  margin: 0 5px;
}

.LandingContactBox_form_side__26pnd {
  /* margin: 50px auto; */
}

.LandingContactBox_sentence__34MBr {
  color: var(--colorMain);
  font-size: 1.22rem;
  text-align: center;
  padding-bottom: 0.5em;
  margin-top: 40px;
}

.LandingContactBox_form__3UZu9 {
  display: flex;
  flex-direction: column;
}

.LandingContactBox_form__3UZu9 input,
.LandingContactBox_form__3UZu9 select,
.LandingContactBox_form__3UZu9 textarea {
  width: 100%;
  margin: 1em 0;
  border-top: none;
  border-left: none;
  border-right: none;
  color: var(--colorMain);
  background: inherit;
  border-bottom: 0.5px solid var(--colorMain);
  font-family: var(--titleFont);
  font-size: 0.8rem;
}

/*.form select:invalid {*/
/*    color: white;*/
/*}*/

.LandingContactBox_form__3UZu9 input::placeholder,
.LandingContactBox_form__3UZu9 textarea::placeholder {
  /*color: var(--colorHover);*/
  opacity: 0.3;
  color: var(--colorMain);
}

.LandingContactBox_form__3UZu9 input:focus,
.LandingContactBox_form__3UZu9 select:focus,
.LandingContactBox_form__3UZu9 textarea:focus {
  outline: none;
  border-bottom: 3px solid var(--colorSecondary);
}

.LandingContactBox_form__3UZu9 .LandingContactBox_form__submit_btn__HJeoU {
  border: none;
  background-color: var(--colorMain);
  color: var(--colorDefaultWhite);
  height: 1.5lh;
  width: 64px;
  font-size: 1rem;
  align-self: last baseline;
}

.LandingContactBox_form__3UZu9 .LandingContactBox_form__submit_btn__HJeoU:hover:enabled {
  opacity: 0.9;
}

.LandingContactBox_form__3UZu9 .LandingContactBox_form__submit_btn__HJeoU:focus:enabled {
  border-bottom: none;
}

.LandingContactBox_form__3UZu9 .LandingContactBox_form__submit_btn__HJeoU:active:enabled {
  opacity: 0.7;
}

.LandingContactBox_form__3UZu9 .LandingContactBox_form__submit_btn__HJeoU:disabled,
.LandingContactBox_form__3UZu9 .LandingContactBox_form__submit_btn__HJeoU[disabled] {
  /*border: 1px solid #999999;*/
  background-color: var(--colorSecondary);
  /*color: var(--colorMainlight);*/
}

.LandingContactBox_form_fields__FAB3y {
  /* columns: 2; */
}

.LandingContactBox_ShowAfter900W__1VtJZ {
  display: none;
}

@media (min-width: 900px) {
  .LandingContactBox_Contact__1Jvzm {
    /* padding-right: 200px; */
  }
  .LandingContactBox_light_blue__jt4gA {
    width: 62%;
  }

  .LandingContactBox_Contact__1Jvzm {
    /* padding-top: 3em; */
    background: inherit;
  }

  .LandingContactBox_container__2bvu_ {
    width: auto;
    display: flex;
    justify-content: space-between;
    /*width: 85%;*/
  }

  .LandingContactBox_heading_side__pfVF6 {
    width: 62%;
    text-align: right;
    margin-top: 45px;
    margin-right: 10vw;
  }

  .LandingContactBox_img__container__EYzVI {
    display: flex;
    margin-top: 95px;
    justify-content: right;
    padding: 0;
  }

  .LandingContactBox_contact__lines__2sMWC {
    display: inherit;
    position: absolute;
    stroke: white;
    z-index: -1;
    /*right: 50vw;*/
    left: -23vw;
    height: 16vw;
    margin: 4.5vw 0;
  }

  .LandingContactBox_form_side__26pnd {
    /* width: 29%; */
    /* margin-top: 50px; */
    /* padding-left: 2vw; */
  }

  .LandingContactBox_img__nQOtJ {
    width: auto;
    height: 23vw;
  }

  .LandingContactBox_header__2uVZU {
    font-size: 1vw;
    color: var(--colorDefaultWhite);
    text-transform: uppercase;
  }

  .LandingContactBox_sentence__34MBr {
    color: var(--colorMain);
    /* font-size: 2rem; */
    text-align: center;
    padding-bottom: 0.5em;
    margin-top: 0;
  }

  .LandingContactBox_contact_form__3DITP {
    justify-content: flex-start;
    padding: 0;
  }

  .LandingContactBox_form__3UZu9 {
    width: 100%;
    max-width: 500px;
  }

  .LandingContactBox_form__3UZu9 input,
  .LandingContactBox_form__3UZu9 select,
  .LandingContactBox_form__3UZu9 textarea {
    color: var(--colorMain);
  }

  .LandingContactBox_form__3UZu9 .LandingContactBox_form__submit_btn__HJeoU {
    align-self: flex-end;
  }
}

.BackButton_backButton__2N_x_ {
    position: fixed;
    left: 1.5em;
    top: 1em;
    z-index: 20;
    color: var(--colorDefaultWhite);
    background-color: var(--colorSecondary);
    padding: .2em 1em;
    border-radius: .2em;
}

.BackButton_backButton__2N_x_:hover {
    opacity: 0.75;
}
