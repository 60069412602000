.container {
    width: 84%;
    margin: 60px auto;
}

.header {
    background-color:  var(--colorMain);
    opacity: 0.8;
    color: white;
    padding: 2em;
    text-transform: uppercase;
}

.blue_cubes_row {
    display: flex;
    justify-content: space-around;
    padding: 0 1em;
}

.blue_cube {
    width: 2em;
    height: 2em;
    background-color: var(--colorMain);
}

.person, .teamCard {
    margin-top: 2em;
}

.lines {
    display: none;
}

@media (min-width: 900px) {
    .container {
        display: flex;
        justify-content: center;
        margin:8em auto 0;
        width: 67%;
        align-items: center;
        gap: 55px;
    }

    .container__blue_cubes {
        grid-template-columns: 1fr;
    }

    .header {
        opacity: 1;
        grid-column: 1 / 3;
        font-size: 2rem;
        padding: 0.2em 0.3em;
        margin: .5em 0;
        justify-self: stretch;
    }

    .person {
        margin-top: 0;
        align-self: stretch;
        width: 187px;
        position: relative;
        font-size: 14px;
    }

    .lines {
        display: flex;
        stroke: var(--colorSecondary);
        width: 433%;
        position: absolute;
        transform-origin: 0 0;
        left: 1vw;
        top: 9vw;
        z-index: -1;
    }

    .teamCard {
        grid-column: 1/3;
        justify-self: center;
    }
}