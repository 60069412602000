.container {
  position: relative;
  width: 84%;
  margin: 124px auto 0;
  max-width: 1200px;
}


.img {
  width: 81%;
  height: 46vw;
  object-fit: cover;
  max-width: 100%;
  display: block;
}

.text_cube {
  background-color: var(--colorMain);
  color: white;
  opacity: 0.8;
  text-transform: uppercase;
}

.text_cube {
  position: absolute;
  font-size: 4.2vw;
  padding: 5% 10%;
  height: fit-content;
  width: 38%;
  right: 0;
  top: 4vw
}

.textbox_1 {
  margin-top: 34px;
  margin-bottom: 0.6lh;
}

.textbox_2 {
  margin-top: 0.6lh;
}

.drorsoft_text {
  color: var(--colorMain);
  font-size: 16px;
}

@media (min-width: 900px) {

  .container {
    margin: auto;
    width: 75%;
    padding-top: 198px;
    display: grid;
    grid-template-columns: [start] 9% [blue-cube-start] 10lh [blue-cube-end] 1fr [img-end] 40% [end];
    grid-template-rows: [start] auto [blue-cube-start] 3lh [img-end] auto [end];
    align-items: center;
  }

  .img_side {
    grid-column-start: start;
    grid-column-end: img-end;
    grid-row-start: start;
    grid-row-end: img-end;
  }

  .img {
    width: 100%;
    height: auto;
  }

  .text_cube {
    position: static;
    font-size: 1.4em;
    padding: 0.5em 50% 3em 10%;
    height: 100%;
    width: 100%;
  }

  .text_cube {
    grid-column-start: blue-cube-start;
    grid-column-end: blue-cube-end;
    grid-row-start: blue-cube-start;
    grid-row-end: end;
  }

  .textbox_1 {
    padding: 0 57px;
    grid-column-start: img-end;
    grid-column-end: end;
    grid-row-start: start;
    grid-row-end: img-end;
  }

  .textbox_2 {
    margin: 50px 50px 0;
    width: 45%;
  }

  .textbox_2 {
    grid-column-start: blue-cube-end;
    grid-column-end: end;
    grid-row-start: img-end;
    grid-row-end: end;
  }

  .hide {
    display: none;
  }
}