.container {
  width: 70%;
  margin: 1em auto;
}

.img {
  width: 100%;
}

.header {
  color: var(--colorDefaultWhite);
  background-color: var(--colorMain);
  opacity: 0.8;
  width: 42vw;
  padding: 1em;
  text-transform: uppercase;

}

.container__grid {
  padding: 2em 0;
}

.projects_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*.project_cube {*/
/*  width: 70%;*/
/*  margin: 0 auto;*/
/*}*/

.project_cube + .project_cube {
  margin-top: 3em;
}

.lines_container {
  padding: 1em 0;
  display: flex;
  justify-content: flex-end;
}

.blue_lines {
  stroke: var(--colorMain);
  transform: scaleX(-1);
  width: 100%;
}

.header_link {
  display: none;
}

.bottom_link {
  color: var(--colorMain);
  text-transform: uppercase;
  font-size: 18px;
  display: flex;
  justify-content: end;
}

@media (min-width: 900px) {
  .container {
    margin: 0 auto;
    padding: 2em 0;
    width: 71%;
  }

  .container__grid {
    display: flex;
    grid-gap: 50px;
    flex-wrap: wrap;
    justify-content: left;
  }

  .img {
    width: 48%;
    display: block;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .header {
    width: min-content;
    color: var(--colorDefaultWhite);
    background-color: var(--colorMain);
    font-size: 2vw;
    padding: 1.2em 1.3em;
    margin-bottom: 42px;
  }

  .header_link {
    display: inherit;
    text-align: center;
    padding-top: 17px;
    font-size: 15px;
  }

  .projects_flex {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .project_cube {
    width: 385px;
    margin-top: 0;
  }

  .project_cube + .project_cube {
    margin-top: 0;
  }

  .bottom_link {
  color: var(--colorMain);
  text-transform: uppercase;
  font-size: 18px;
  text-align: right;
  }
}