.header_container {
    position: relative;
    width: 84%;
    margin: 124px auto 0;
}

.img {
    width: 81%;
    height: 45vw;
    max-width: 100%;
    display: block;
    object-fit: cover;
}

.text_cube {
  background-color: var(--colorMain);
  color: white;
  opacity: 0.8;
  text-transform: uppercase;
}

.text_cube {
  position: absolute;
  font-size: 4.2vw;
  padding: 5% 6%;
  height: fit-content;
  width: 38%;
  right: 0;
  top: 4vw
}

/*.header {*/
/*    background-color:  var(--colorMain);*/
/*    opacity: 0.8;*/
/*    color: white;*/
/*    padding: 2em;*/
/*    text-transform: uppercase;*/
/*    margin-top: -1.5em;*/
/*}*/

@media (min-width: 900px) {
    .WhatWeOffer {
        padding: 2em 0;
        margin-top: -2em;
    }
    .header_container {
        display: flex;
        margin: 2em auto;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .blue_cube {
        background: var(--colorMain);
        position: relative;
        left: 15vw;
        bottom: -2em;
        height: 7vw;
        width: 6.5vw;
    }

    .text_cube {
        position: relative;
        font-size: 1.8vw;
        padding: 2% 5% 2% 2%;
        height: 23vw;
        width: 17%;
        right: auto;
        top: auto;
        left: -13vw;
    }

    .img {
        height: 40vw;
        width: 80vw;
        object-fit: cover;
    }

    .container {
        display: flex;
    }

    .offers__container {
        columns: 2
    }

    .offer__container {

    }

    .left_cube {
        width: 81px;
        height: 86.9px;
        background: var(--colorMain);
        margin-right: 203px;
        margin-top: 170px;
    }

    .right_cube {
        width: 393px;
        height: 222px;
        background: var(--colorMain);
        margin-left: 99px;
        margin-top: 250px;
    }

    .numbered_offer {

    }

    /*.header {*/
    /*    width: 48%;*/
    /*    opacity: 1;*/
    /*    font-size: 1.5rem;*/
    /*    padding: 0.2em 0.3em;*/
    /*    text-transform: uppercase;*/
    /*    margin-top: 0;*/
    /*}*/

    /*.numbered_offer {*/
    /*    max-width: 700px;*/
    /*}*/

    /*.container:nth-of-type(3) .numbered_offer,*/
    /*.container:nth-of-type(5) .numbered_offer,*/
    /*.container:nth-of-type(7) .numbered_offer*/
    /*{*/
    /*    margin-left: 5em;*/
    /*}*/
}

/*@media (min-width: 1200px) {*/
/*    .container:nth-of-type(3) .numbered_offer,*/
/*    .container:nth-of-type(5) .numbered_offer,*/
/*    .container:nth-of-type(7) .numbered_offer*/
/*    {*/
/*        margin-left: 13em;*/
/*    }*/
/*}*/

/*@media (min-width: 1800px) {*/
/*    .container:nth-of-type(3) .numbered_offer,*/
/*    .container:nth-of-type(5) .numbered_offer,*/
/*    .container:nth-of-type(7) .numbered_offer*/
/*    {*/
/*        margin-left: 18em;*/
/*    }*/
/*}*/