@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');

    /* ******** Set to Zero the auto-css ******** */
*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

/* ******** Set Root varables of the webapp ******** */
:root {
  --titleFont: "Ubuntu Condensed", "Alef", sans-serif;
  --mainFont: "Oxygen", "Alef", sans-serif;
  --colorMain: #05447c;
  --colorMainlight: rgba(5, 68, 124, 0.8);
  --colorSecondary: #24aadf;
  --colorDefaultWhite: rgb(238, 238, 238);
  --transformMenu: 1000ms;
  --colorHover: #29abe2;
  --whiteHover: #ffffff;
  --mutedText: #bdccd4;
  --fixedFontSize: 1rem;

  /* Colors not from Dror banding*/
  --lightGray: #e8f2f9;
  --lightYellowGray: #e5e2d3;
  --lightYellow: #cdcdc3;
  --brownBoard: #a06344;
  --lightBrown: #dfb198;
  --lighterBrown: rgba(223, 177, 152, 0.3);
  --lightBlue: #a1c4c0;
  --lightGreen: #bccab1;
  --orange: #f57a03;
  --brightRed: #FF2211;
}


/* ******** Set Text font & size ******** */
body {
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*sans-serif;*/
  font-family: var(--mainFont);
}


.card-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

/*.card-1:hover {*/
/*  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);*/
/*}*/

.card-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.card-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.card-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.button {
  background-color: var(--colorMain);
  color: var(--colorDefaultWhite);
  padding: 1em 2em;
  width: fit-content;
  align-self: start;
  justify-self: end;
  border: none;
  font-size: 1rem;
  border-radius: 2em;
}

.button:hover {
  opacity: 0.9;
}

.button:focus:enabled {
  border-bottom: none;
}

.button:active:enabled {
  opacity: 0.7;
}


@media (min-width: 600px) {
  html {
    font-size: 18px;
  }

}

h1,h2,h3,h4,h5 {
  font-family: var(--titleFont);
}

a {
  text-decoration: none;
  color: inherit;
}