.wrapper {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
}

@media (min-width: 1200px) {
    .wrapper {
        grid-template-rows: 4rem auto;
    }
}