.cta {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.5rem 3rem;
}

.links {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: auto;
}

.background, .transparent {
    background: var(--colorMain);
    color: white;
    /*width: 100%;*/
    z-index: 15;
}

@media (min-width: 900px) {
    .cta {
        position: fixed;
        bottom: 50vh;
        left: 2.5rem;
        width: fit-content;
        background: white;
        /*width: 160px;*/
        transform: translate(-50%, -100%) rotate(90deg);
        letter-spacing: 1px;
        font-size: 0.8rem;
        transition: color 1s;
        z-index: 15;
        padding: 0.5rem;
    }

    .links span {
        margin: 0 0.5rem;
    }

    .background {
        color: var(--colorMain);
        background: white;
    }

    .transparent {
        color: white;
        background: none;
    }
}