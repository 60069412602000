.NumberedText {
    display: flex;
    /*justify-content: space-between;*/
    padding: 1em;
    break-inside: avoid-column;
}

.number {
    color: var(--colorSecondary);
    justify-self: right;
    align-self: start;
    margin-top: -0.1em;
    font-size: 3rem;
    width: 2ch;
}

.line {
    background: var(--colorMain);
    justify-self: center;
    padding: 3px;
    margin: 0 16px;
}

.text_wrapper {
    text-align: left;
    width: 77%;
    color: var(--colorMain);
}

.text_title {
    color: var(--colorSecondary);
    text-transform: uppercase;
}

.content {
    font-size: 1rem;
}

.project_img {
    width: 1.9ch;
}

