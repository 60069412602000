.backButton {
    position: fixed;
    left: 1.5em;
    top: 1em;
    z-index: 20;
    color: var(--colorDefaultWhite);
    background-color: var(--colorSecondary);
    padding: .2em 1em;
    border-radius: .2em;
}

.backButton:hover {
    opacity: 0.75;
}