.hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-background {
  background-image: url("../../Images/hero-img-02.jpeg");
  background-position: center;
  background-color: var(--lightGray);
  background-size: cover;
}

.hero__white_lines {
  position: absolute;
  top: 20vh;
  stroke: white;
  width: 100%;
}

#hero-slogan {
  font-size: 3rem;
  text-align: center;
  color: white;
  word-spacing: 9999rem;
  text-transform: uppercase;
  height: fit-content;
  margin: auto;
}

@media (min-width: 900px) {
  #hero-slogan {
    font-size: 4rem;

  }
  .hero__white_lines {
    height: 13%;
    top: 26vh;
    position: absolute;
    margin: auto;
  }
}