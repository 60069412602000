/* ********** THE BURGER ********** */
/* Basic Burger Menu*/
#menuBurger {
    position: fixed;
    right: 1.5em;
    top: 1.5em;
    z-index: 30;
}

#menuBurger:hover {
    cursor: pointer;
}

/* Every peace of the Burger */
#menuBurger .menuBurgerLevel {
    position: relative;
    top: 0;
    width: 30px;
    height: 3px;
    margin-bottom: 4px;
    transition: all var(--transformMenu) ease-in-out;
    transition-property: transform, top;
}


/* Setting the positions by sets */
#menuBurger #m1 {
    --burger: 0deg;
    --plus: 270deg;
    --x: 135deg;
    --xx: 405deg;
    --top: 7px;
}

#menuBurger #m2 {
    --burger: 0deg;
    --plus: -540deg;
    --x: 225deg;
    --xx: 495deg;
    --top: 0px;
}

#menuBurger #m3 {
    --burger: 0deg;
    --plus: -360deg;
    --x: 225deg;
    --xx: 495deg;
    --top: -7px;
}

/*  ********** Mouse effects the Burger ********** */
#menuBurger:hover .menuBurgerLevel {
    transform: rotate(var(--plus));
    top: var(--top);
}

#menuBurger.active .menuBurgerLevel {
    transform: rotate(var(--x));
    top: var(--top);
}

#menuBurger.active:hover .menuBurgerLevel {
    transform: rotate(var(--xx));
    top: var(--top);
}

/* ********** THE MENU BOX ********** */

/* Design the Box */
#menuBox {
    position: fixed;
    z-index: 25;
    right: -100vw;
    width: 100vw;
    transition: right var(--transformMenu) ease-in-out;
}

/* Design the Box BG */
#menuBox::before {
    content: "";
    position: fixed;
    top: 0;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    background-color: var(--colorMain);
    opacity: 0.9;
    transition: right var(--transformMenu) ease-in-out;
    z-index: -1;
}

#menuBox.active,
#menuBox.active::before {
    right: 0;
}

.menuItemsContainer {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    color: var(--mutedText);
}

.menuAnchor {
    padding: .26em 0;
}



#menuBox #menuAnchors .menuAnchor:hover,
#menuBox .menuSocial :hover,
.menuBurgerLevel:hover,
.comInfo__list a:hover {
    color: var(--whiteHover);
}

/* Design the Social Box */
#menuBox .menuSocial {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#menuBox .menuSocial a{
    text-transform: uppercase;
}

#menuBox .menuSocial .socialBtn svg {
    height: 35px;
    margin-top: 11px;
    margin-left: 20px;
}

#menuBox .menuSocial .socialBtn svg:hover {
    cursor: pointer;
}

#menuBox .menuSocial .socialBtn svg path {
    fill: var(--colorDefaultWhite);
}

#menuBox .menuSocial .socialBtn svg path:hover {
    fill: var(--colorHover);
}


#m1.logoOnWhite,
#m2.logoOnWhite,
#m3.logoOnWhite {
    background-color: var(--colorMain);
}

.comInfo {
    display: none;
}

.comInfo__list {
    list-style: none;
}

.comInfo__list li {
    padding: 0.1em 0;
    font-size: 0.9rem;
}

@media (min-width: 900px) {
    .menuAnchor {
        padding: .2em 0;
    }

    #menuBox::before {
        right: -23em;
        width: 23em;
    }

    .menuItemsContainer {
        text-align: right;
        align-items: flex-end;
        padding: 1.5em;
    }

    .comInfo {
        display: block;
    }

    .menuSocial {
        justify-content: space-between;
        font-size: .9rem;
    }

}