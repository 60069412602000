.container {
  /* position: relative; */
  /* width: 84%; */
  margin: 60px 30px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.flexit {
  display: flex;
  flex-direction: column;
}

.flexitcol {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding-top: 50px;
}

.text_cube {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 10%;
  background-color: var(--colorMain);
  font-size: 2.5rem;
  /* width: 25%;
  height: 10vh;
  min-width: 300px; */
  color: white;
  opacity: 0.8;
  text-transform: uppercase;
}

.contact_cube {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding: 15px;
  color: var(--colorMain);
  border: 1px solid #2dabe3;
  font-family: var(--titleFont);
  font-size: 19.9px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  line-height: 1.22;
  width: 80vw;
  height: fit-content;
  min-width: 300px;
  margin-top: 20px;
}

.images_gallery_bullet {
  box-shadow: none;
  padding: 4px;
  position: absolute;
  bottom: -50px;
  background-color: red;
  width: 90px;
}

.images_gallery_bullet_active {
  background: var(--colorMain);
}
.quoteSvg {
  width: 50px;
  height: 40px;
  border-bottom: 2px solid #2dabe3;
  position: relative;
  top: 20px;
  z-index: 30;
}
.swiperCont {
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
  height: 200px;
  :global(.swiper-pagination) {
    width: 35% !important;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiperSlide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: MyriadHebrew;
  font-size: 18.8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #2dabe3;
}

.swiperSlide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quoteName {
  font-size: 15px;
  position: relative;
  top: 80px;
  min-width: fit-content;
  color: var(--colorMain);
}

.contDesc {
  font-family: var(--titleFont);
  font-size: 20px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  max-width: 90vw;
  color: #05447c;
}
.imgGallery {
  width: 90vw;
}
.imgGalleryDesktop {
  :global(.image-gallery-bullets) {
    bottom: 20px;
  }
  :global(.image-gallery-bullet.active) {
    background-color: var(--colorMain);
  }
  /* max-height: 30vh; */
}
.imgGalleryMobile {
  max-width: 170px;
  margin-top: 20px;
  :global(.image-gallery-bullet.active) {
    background-color: var(--colorMain);
  }
  :global(.image-gallery-bullets) {
    top: 20px;
  }
}
.bottomLines {
  display: none;
}

@media (min-width: 900px) {
  .container {
    position: relative;
    width: 84%;
    margin: 6vh auto 0 auto;
    max-width: 1200px;
    height: 94vh;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  .flexit {
    display: flex;
    flex-direction: row;
  }

  .flexitcol {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .text_cube {
    order: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10%;
    background-color: var(--colorMain);
    font-size: 2rem;
    width: 25%;
    height: 10vh;
    min-width: 300px;
    color: white;
    opacity: 0.8;
    text-transform: uppercase;
    margin: 0;
  }

  .contact_cube {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding: 15px;
    order: 2;
    color: var(--colorMain);
    border: 1px solid #000000;
    font-family: var(--titleFont);
    font-size: 19.9px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 1.22;
    width: 20%;
    height: fit-content;
    min-width: 300px;
    margin-top: 0;
  }

  .images_gallery_bullet {
    box-shadow: none;
    padding: 4px;
    position: absolute;
    bottom: -50px;
    background-color: red;
    width: 90px;
  }

  .images_gallery_bullet_active {
    background: var(--colorMain);
  }

  .swiperCont {
    margin-top: 0;
    margin-bottom: 0;
    order: 5;
    width: 40vw;
    /* max-width: 670px; */
    height: 180px;
    :global(.swiper-pagination) {
      width: 35% !important;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiperSlide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: MyriadHebrew;
    font-size: 18.8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #2dabe3;
  }

  .swiperSlide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .contDesc {
    order: 4;
    font-family: var(--titleFont);
    font-size: 22.4px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    width: 40vw;
    max-width: 670px;
    color: #05447c;
    align-self: baseline;
  }
  .imgGalleryDesktop {
    width: 30vw;
    order: 3;
    max-width: 760px;
    :global(.image-gallery-bullets) {
      bottom: -20px;
    }
    :global(.image-gallery-bullet.active) {
      background-color: var(--colorMain);
    }
    /* max-height: 30vh; */
  }
  .imgGalleryMobile {
    width: 10vw;
    order: 6;
    position: relative;
    margin-top: 0;
    /* top: -20%; */
    /* margin: auto; */
    right: 5%;
    top: 25%;
    max-width: 170px;
    :global(.image-gallery-bullet.active) {
      background-color: var(--colorMain);
    }
    :global(.image-gallery-bullets) {
      top: -20px;
    }
  }
  .bottomLines {
    order: 8;
    position: absolute;
    right: -5%;
    /* margin: auto; */
    bottom: 0px;
    display: flex;
    align-items: flex-end;
    z-index: 90;
  }
  .bottomLinesSvg {
    height: 300px;
  }
}

@media (min-width: 1700px) {
  .imgGalleryDesktop {
    width: 50vw;
  }
  .text_cube {
    padding: 15%;
    height: 20vh;
  }
  .contact_cube {
    min-width: 350px;
  }
}
